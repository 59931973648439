import React, { useEffect, useContext, useRef } from "react"
import { graphql } from "gatsby"
import { useIntl } from "react-intl"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Layout from "../layout"
import SEO from "../seo"
import EmailCaptureModal from "../email-capture-modal"

import { IntlContext } from "../../intl"
import GetStartedCTA from "../get-started-cta"

export const query = graphql`
	query($id: String!) {
		prismicProducts(id: { eq: $id }) {
      uid
      url
      lang
			data {
				title {
					text
				}
        headline {
          text
        }
        step_one
        step_two
        step_three
        mailing_list_title {
          text
        }
        mailing_list_content {
          html
        }
        postcard_title {
          text
        }
        postcard_content {
          html
        }
        above_the_fold_image {
          alt
          fluid {
            src
          }
        }
			}
      alternate_languages {
        lang
        url
      }
		}
	}
`

const ProductsServicesPage = ({ data }) => {

  const intl = useIntl()
  const modalTriggerRef1 = useRef()
  const modalTriggerRef2 = useRef()

  const { changeLocale, setAlts } = useContext(IntlContext)
  useEffect(() => {
    changeLocale(data.prismicProducts.lang);
    setAlts(data.prismicProducts.alternate_languages || [])
  }, [changeLocale, setAlts, data])
  
  return (
	<Layout container={false}>
		<SEO
      lang={data.prismicProducts.lang} 
      title={data.prismicProducts.data.title.text} 
      meta={[{
        "http-equiv": "content-language", 
        content: data.prismicProducts.lang
      }]} 
      links={data.prismicProducts.alternate_languages.concat([data.prismicProducts]).map(alt => ({
        rel: "alternate", 
        hreflang: alt.lang, 
        href: `https://www.customerradius.com${alt.url}`
      })).concat([{
        rel: "alternate", 
        hreflang: "x-default", 
        href: "https://www.customerradius.com/products-services"
      }])} />
		<div className="hero is-primary">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-vcentered">
            <div className="column is-three-fifths">
              <h1 className="title">{data.prismicProducts.data.title.text}</h1>
              <button className="button is-warning is-medium" ref={modalTriggerRef1}>
                {intl.formatMessage({ id: "email_capture.request_early_access" })}
              </button>
              <EmailCaptureModal triggerRef={modalTriggerRef1} intl={intl} sourceDetail={'Products and Services'} />
            </div>
            <div className="column is-two-fifths">
              <figure className="image">
                <img src={data.prismicProducts.data.above_the_fold_image.fluid.src}
                  alt={data.prismicProducts.data.above_the_fold_image.alt} />
              </figure>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="section">
      <div className="container has-text-centered">
        <h2 className="title pb-5">
          {data.prismicProducts.data.headline.text}
        </h2>
        <div className="columns is-vcentered">
          <div className="column">
            <div className="box has-background-info">
              <h3 className="title is-size-1-desktop is-size-2-touch has-text-white">
              <FontAwesomeIcon icon="map-marker-alt" />
              </h3>
              <h3 className="subtitle is-size-3-desktop is-size-4-touch has-text-white">
                {data.prismicProducts.data.step_one}
              </h3>
            </div>
          </div>
          <div className="column is-1 is-size-1-desktop is-size-2-touch">
            <FontAwesomeIcon icon="arrow-right" className="is-hidden-mobile" />
            <FontAwesomeIcon icon="arrow-down" className="is-hidden-tablet" />
          </div>
          <div className="column">
            <div className="box has-background-info">
              <h3 className="title is-size-1-desktop is-size-2-touch has-text-white">
              <FontAwesomeIcon icon="address-card" />
              </h3>
              <h3 className="subtitle is-size-3-desktop is-size-4-touch has-text-white">
                {data.prismicProducts.data.step_two}
              </h3>
            </div>
          </div>
          <div className="column is-1 is-size-1-desktop is-size-2-touch">
            <FontAwesomeIcon icon="arrow-right" className="is-hidden-mobile" />
            <FontAwesomeIcon icon="arrow-down" className="is-hidden-tablet" />
          </div>
          <div className="column">
            <div className="box has-background-info">
              <h3 className="title is-size-1-desktop is-size-2-touch has-text-white">
              <FontAwesomeIcon icon="paper-plane" />
              </h3>
              <h3 className="subtitle is-size-3-desktop is-size-4-touch has-text-white">
                {data.prismicProducts.data.step_three}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="section">
      <div className="container">
        <div className="columns">
          <div className="column">
            <h2 className="title has-text-centered has-text-primary is-uppercase">
              {data.prismicProducts.data.mailing_list_title.text}
            </h2>
            <div className="content is-large" dangerouslySetInnerHTML={{ __html: data.prismicProducts.data.mailing_list_content.html }}></div>
          </div>
          <div className="column">
            <h2 className="title has-text-centered has-text-primary is-uppercase">
              {data.prismicProducts.data.postcard_title.text}
            </h2>
            <div className="content is-large" dangerouslySetInnerHTML={{ __html: data.prismicProducts.data.postcard_content.html }}></div>
          </div>
        </div>
      </div>
    </div>
    <section className="section">
      <GetStartedCTA />
    </section>
    <div className="hero is-primary">
      <div className="hero-body has-text-centered">
        <button className="button is-warning is-large" ref={modalTriggerRef2}>
          {intl.formatMessage({ id: "email_capture.request_early_access" })}
        </button>
        <EmailCaptureModal triggerRef={modalTriggerRef2} intl={intl} sourceDetail={'Products and Services'} />
      </div>
    </div>
	</Layout>
)}

export default ProductsServicesPage
